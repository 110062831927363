import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  fontSize: "10rem",
  marginTop: 0,
  marginBottom: 64,
}
const navStyles = {
  display: "flex"
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Digital Rain</title>
      <h1 style={headingStyles}>
        Digital Rain
      </h1>

      <p>
        Information technology company
      </p>

    </main>
  )
}

export default IndexPage
